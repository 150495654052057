<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="settlementpartyId">
          <el-input
              v-model="query.companyName"
              clearable
              placeholder="请输入结算方名称"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
          <!-- <Settlement v-model="query.settlementpartyId" style="width: 260px" /> -->
        </el-form-item>
        <el-form-item prop="parkingLotInfoId">
          <SuggestParking
              v-model="query.parkingLotInfoId"
              :motionType="1"
              style="width: 260px"
          />
        </el-form-item>
        <el-form-item prop="orderStatus">
          <el-select
              v-model="query.orderStatus"
              clearable
              placeholder="请选择-订单状态"
              style="width: 260px"
          >
            <el-option label="正常" value="0"></el-option>
            <el-option label="无效" value="1"></el-option>
            <el-option label="审核" value="2"></el-option>
            <el-option label="复议" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="payStatus">
          <el-select
              v-model="query.payStatus"
              clearable
              placeholder="请选择-付款状态"
              style="width: 260px"
          >
            <el-option label="无需支付" value="0"></el-option>
            <el-option label="未付款" value="1"></el-option>
            <el-option label="已付款" value="2"></el-option>
            <el-option label="支付中" value="3"></el-option>
            <el-option label="退款中" value="4"></el-option>
            <el-option label="已退款" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="parkingOrderType">
          <el-select
              v-model="query.parkingOrderType"
              placeholder="请选择-订单类型"
              style="width: 260px"
          >
            <el-option label="临停" value="0"></el-option>
            <el-option label="长租" value="1"></el-option>
            <el-option label="白名单" value="2"></el-option>
            <el-option label="黑名单" value="3"></el-option>
            <!-- <el-option label="预存" value="4"></el-option>
            <el-option label="访客" value="5"></el-option>
            <el-option label="共享" value="6"></el-option> -->
            <!-- <el-option label="预约" value="7"></el-option>
            <el-option label="分组" value="8"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item prop="OrderCode">
          <el-input
              v-model="query.OrderCode"
              placeholder="订单编号"
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="payType">
          <el-select
            v-model="query.payType"
            placeholder="请选择-付款方式"
            style="width: 260px"
          >
            <el-option label="线上付款" value="1"></el-option>
            <el-option label="线下付款" value="2"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="VehicleId">
          <NumplateSuggest v-model="query.VehicleId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="phoneNumber">
          <el-input
              v-model="query.phoneNumber"
              placeholder="搜索手机号码"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="feeEarners">
          <Person
              v-model="query.feeEarners"
              :parkingId="query.parkingLotInfoId"
              placeholder="请选择-收费人员"
              style="width: 260px"
          />
        </el-form-item>
        <el-form-item prop="parkingGoType">
          <el-select
              v-model="query.parkingGoType"
              placeholder="请选择-驶出状态"
              style="width: 260px"
          >
            <el-option label="已驶出" value="0"></el-option>
            <el-option label="未驶出" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="IntoName">
          <Passage
              v-model="query.IntoName"
              :parkingId="query.parkingLotInfoId"
              placeholder="请选择-驶入口"
              style="width: 260px"
          />
        </el-form-item>
        <el-form-item prop="exitName">
          <Passage
              v-model="query.exitName"
              :parkingId="query.parkingLotInfoId"
              device-type="2"
              placeholder="请选择-驶出口"
              style="width: 260px"
          />
        </el-form-item>
        <el-form-item prop="parkingTimeType">
          <div class="xxd-input__wrap">
            <el-select v-model="query.parkingTimeType" placeholder="时间范围">
              <el-option label="驶入时间" value="1"></el-option>
              <el-option label="驶出时间" value="2"></el-option>
              <el-option label="驶入驶出" value="3"></el-option>
            </el-select>
            <el-date-picker
                v-model="query.timeValues"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
                align="right"
                end-placeholder="结束日期"
                range-separator="至"
                start-placeholder="开始日期"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetQueryForm">重置</el-button>
          <el-button type="primary" @click="doSearch">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          <span v-if="query.timeValues != null"
          >当前时间:<span class="total-size">{{
              query.timeValues.join("~")
            }}</span>
            &nbsp; &nbsp;</span
          >共<span class="total-size">{{ total }}</span
        >条信息 &nbsp; &nbsp; &nbsp; 订单应收金额：<span class="total-size">{{
            parseFloat(receivableAmountStats).toFixed(2)
          }}</span
        >元 &nbsp; &nbsp; &nbsp; 订单实收金额：<span class="total-size">{{
            parseFloat(transactionAmountStats).toFixed(2)
          }}</span
        >元 &nbsp;&nbsp; &nbsp;
          <!-- 线下缴费：<span class="total-size"></span>元 &nbsp; &nbsp; -->
          优惠券金额：<span class="total-size">{{
            parseFloat(discountAmountStats).toFixed(2)
          }}</span
        >元
        </div>
        <div class="op-extra">
          <icon-button
              v-if="hasRights('002002001')"
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
              align="center"
              fixed="left"
              label="停车场名称"
              prop="parklotName"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              fixed="left"
              label="车牌号码"
              prop="numplate"
              width="180"
          >
            <template slot-scope="scope">
              <Numplate :type="scope.row.extendInfo.color">{{
                  scope.row.numplate
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单类型" width="100">
            <template slot-scope="scope">
              <span>{{ orderType[scope.row.extendInfo.orderType] }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="订单状态" width="180">
            <template slot-scope="scope">
              <span>{{ orderStatus[scope.row.orderStatus] }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="驶入时间"
              prop="intoDateTime"
              width="180"
          ></el-table-column>
          <el-table-column align="center" label="驶出时间" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.parkingOrderExit.id !== ''">{{
                  scope.row.parkingOrderExit.exitDateTime
                }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="停车时长"
              prop="parkingTime"
              width="180"
          >
          </el-table-column>
          <el-table-column align="center" label="订单金额" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.parkingOrderExit.id !== ''">{{
                  parseFloat(scope.row.parkingOrderExit.receivableAmount).toFixed(
                      2
                  )
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="应收金额" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.parkingOrderExit.id !== ''">{{
                  parseFloat(scope.row.parkingOrderExit.receivableAmount).toFixed(
                      2
                  )
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="实收金额">
            <el-table-column align="center" label="线上缴费" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.parkingOrderExit.id !== ''">{{
                    parseFloat(
                        scope.row.parkingOrderExit.transactionAmount
                    ).toFixed(2)
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="线下缴费" width="100">
              <!-- <template slot-scope="scope">
                <span v-if="scope.row.payType === '2'">{{
                  scope.row.transactionAmount
                }}</span>
              </template> -->
            </el-table-column>
            <el-table-column align="center" label="优惠金额" width="100">
              <el-table-column align="center" label="结算" width="100">
              </el-table-column>
              <el-table-column align="center" label="非结算" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.parkingOrderExit.id !== ''">{{
                      parseFloat(
                          scope.row.parkingOrderExit.discountAmount
                      ).toFixed(2)
                    }}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table-column>

          <!-- <el-table-column label="非实收金额" align="center">
            <el-table-column label="抵扣金额" width="100" align="center">
            </el-table-column>
            <el-table-column label="岗亭减免" width="100" align="center">
            </el-table-column>
          </el-table-column> -->

          <!-- <el-table-column prop="name" label="非实收金额" width="180">
          </el-table-column> -->
          <!-- <el-table-column prop="payType" label="付款方式" width="180">
          </el-table-column> -->
          <el-table-column align="center" label="岗亭收费人" width="180">
            <template>岗亭收费</template>
          </el-table-column>
          <el-table-column
              align="center"
              label="订单编号"
              prop="orderCode"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="缴费状态"
              prop="orderCode"
              width="240"
          >
            <!-- <template slot-scope="scope">
            <span v-if="scope.row.parkingOrderExit.extendInfo !== undefined">
              <span
                v-if="scope.row.parkingOrderExit.receivableAmount === 0"
                >无需付款</span
              >
              <span v-else>{{
                scope.row.isarray == 1 ? "已付款" : "未付款"
              }}</span>
            </span>
          </template> -->
            <template slot-scope="scope">
              <span>{{ payStatus[scope.row.payStatus] }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="用户手机号"
              prop="mobile"
              width="180"
          >
          </el-table-column>
          <el-table-column align="center" label="驶入口" width="180">
            <template slot-scope="scope">
              {{ scope.row.extendInfo.intoName }}
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="驶出口"
              prop="name"
              width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.parkingOrderExit.id !== ''">{{
                  scope.row.parkingOrderExit.extendInfo.exitName
                }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              prop="name"
              width="280px"
          >
            <template slot-scope="scope">
              <el-button
                  v-if="hasRights('002002002')"
                  class="common-text"
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="goDetail(scope.row)"
              >详情
              </el-button
              >
              <el-button
                  v-if="hasRights('002002003')"
                  class="common-text"
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="complete(scope.row.id)"
              >完结
              </el-button
              >
              <el-button
                  v-if="hasRights('002002004')"
                  class="common-text"
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="modifyLicensePlate(scope.row.id)"
              >修改车牌
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 驶入驶出图片弹窗 -->
      <!-- <el-dialog
        class="common-dialog"
        title="车辆快照"
        :visible.sync="dialogFormVisible"
        :append-to-body="true"
      >
        <div class="photoShow">
          <div class="driveInPhoto">
            <div>
              <el-carousel trigger="click" height="300px" class="photo-width">
                <el-carousel-item>
                  <el-image
                    :src="src"
                    @click="photoDialogFormVisible = true"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div>驶入：</div>
          </div>
          <div class="driveOutPhoto">
            <div>
              <el-carousel trigger="click" height="300px" class="photo-width">
                <el-carousel-item>
                  <el-image
                    :src="src"
                    @click="photoDialogFormVisible = true"
                  ></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div>驶出：</div>
          </div>
        </div>
      </el-dialog> -->

      <!-- 预览驶入驶出大图 -->
      <!-- <el-dialog
        class="common-dialog"
        title="预览图"
        :visible.sync="photoDialogFormVisible"
        :append-to-body="true"
      >
        <div>
          <el-image :src="src"></el-image>
        </div>
      </el-dialog> -->

      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 完结弹出窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="completeDialogFormVisible"
        class="common-dialog"
        title="完结订单"
        width="550px"
    >
      <el-form
          ref="completeForm"
          :model="completeForm"
          :rules="rules"
          label-width="100px"
      >
        <el-form-item label="驶出时间" required>
          <el-form-item prop="endTime">
            <el-date-picker
                v-model="completeForm.endTime"
                placeholder="选择日期时间"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('completeForm')"
        >完结
        </el-button
        >
        <!-- <el-button @click="resetForm('completeForm')">重置</el-button> -->
      </div>
    </el-dialog>

    <!-- 修改车牌弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="modifyDialogFormVisible"
        class="common-dialog"
        title="修改车牌"
        width="550px"
    >
      <el-form
          ref="modifyForm"
          :model="modifyForm"
          :rules="modifyRules"
          label-width="100px"
      >
        <el-form-item label="车牌颜色" required>
          <el-form-item prop="color">
            <el-select v-model="modifyForm.color" placeholder="请选择车牌颜色">
              <el-option label="黄牌" value="01"></el-option>
              <el-option label="蓝牌" value="02"></el-option>
              <el-option label="黑牌" value="06"></el-option>
              <el-option label="绿色" value="03"></el-option>
              <el-option label="黄绿" value="04"></el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="车牌号码" prop="numPlate">
          <el-input v-model="modifyForm.numPlate"></el-input>
        </el-form-item>
        <el-form-item label="原因" prop="reason">
          <el-input
              v-model="modifyForm.reason"
              maxlength="100"
              show-word-limit
              type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="modifySubmit('modifyForm')"
        >确定
        </el-button
        >
        <el-button @click="closeModify">取消</el-button>
      </div>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SuggestParking from "@/components/SuggestParking";
// import Settlement from "@/components/Settlement";
import Person from "@/components/Person";
import Numplate from "@/components/Numplate";
import NumplateSuggest from "@/components/NumplateSuggest";
import {addParkingOrderExit, getParkingOrderByPage, updateVehicleNumPlate,} from "@/api/parkingOrder";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import Passage from "@/components/Passage";
import {dateFormat} from "@/utils/utils";
import {doExport} from "@/api/common";

export default {
  name: "share_parking-order",
  mixins: [BaseMixin, Permission],
  data() {
    const today = dateFormat("yyyy-mm-dd", new Date());
    const beginDate = today + " 00:00:00";
    // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
    const endDate = today + " 23:59:59";
    return {
      // isLoading: false,
      completeDialogFormVisible: false, //控制完结弹窗
      modifyDialogFormVisible: false, //修改车牌弹窗
      total: "",
      discountAmountStats: 0.0,
      receivableAmountStats: 0.0,
      transactionAmountStats: 0.0,
      // 完结表单
      completeForm: {
        parkingOrderIntoId: "",
        endTime: "",
      },
      rules: {
        endTime: [
          {required: true, message: "请选择日期时间", trigger: "change"},
        ],
      },
      //修改车牌表单
      modifyForm: {
        parkingOrderIntoId: "",
        color: "",
        numPlate: "",
        reason: "",
      },
      modifyRules: {
        color: [
          {required: true, message: "请选择车牌颜色", trigger: "change"},
        ],
        numPlate: [
          {required: true, message: "请输入车牌号码", trigger: "blur"},
        ],
        reason: [
          {required: true, message: "请输入修改原因", trigger: "blur"},
          {max: 100, message: "长度不能超过100个字符", trigger: "blur"},
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dialogFormVisible: false, //控制审核弹窗层显示
      photoDialogFormVisible: false,
      orderType: {
        // 0 临停，1白名单，2黑名单，3长租，4预存，5访客，6共享
        0: "临停",
        1: "白名单",
        2: "黑名单",
        3: "长租",
        4: "预存",
        5: "访客",
        6: "共享",
      },
      orderStatus: {
        0: "正常",
        1: "无效",
        2: "审核",
        3: "复议",
        "": "正常", //平台数据迁移问题，后期修复2022-09-09lz
      },
      payStatus: {
        "": "",
        0: "无需付款",
        1: "未付款",
        2: "已付款",
        3: "支付中",
        4: "退款中",
        5: "已退款",
      },
      query: {
        companyName: "", //结算方名称
        parkingLotInfoId: "", //停车场id
        OrderCode: "", //订单编号
        OrderType: "1", //平台  0运营平台  1共享  3联网
        orderStatus: "", //订单状态 0:正常 1无效 2审核 3复议
        payStatus: "", //付款状态 0:无需支付 1未付款 2已支付 3支付中 4退款中 5已退款
        payType: "", // 缴费方式  0现金 2人工 1优惠券
        VehicleId: "", //车牌号id
        parkingType: "2", //路外查询
        phoneNumber: "", //手机号
        feeEarners: "", //岗亭人员
        parkingGoType: "", //驶入驶出状态  0驶入  1 驶出
        parkingTimeType: "1", //时间范围
        timeValues: [beginDate, endDate], //时间
        beginTime: "",
        endTime: "",
        IntoName: "", //驶入口
        exitName: "", //驶出口
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
    };
  },
  components: {
    SuggestParking,
    // Settlement,
    Numplate,
    Passage,
    NumplateSuggest,
    Person,
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    //查看停车订单详情
    goDetail(item) {
      sessionStorage.setItem("order_detail", JSON.stringify(item));
      this.$router.push("/share/parking_order/parking_order_detail");
    },
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.timeValues = [];
      this.pagination.pageSize = 10;
      this.getNowDate();
      this.doSearch();
    },
    doSearch() {
      this.pagination.pageNo = 1;
      this.search();
    },
    async search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);
      if (this.query.timeValues != null) {
        params["beginTime"] = this.query.timeValues[0];
        params["endTime"] = this.query.timeValues[1];
      }
      const res = await getParkingOrderByPage(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        if (returnObject !== "") {
          this.dataList = returnObject.list;
          this.pagination.totalSize = returnObject.count;
          this.pagination.totalPages = returnObject.totalPage;
          this.total = returnObject.count;
          this.form.exportSize = returnObject.count;
          if (returnObject.stats.discountAmountStats.sum !== undefined) {
            this.discountAmountStats =
                returnObject.stats.discountAmountStats.sum;
          }
          if (returnObject.stats.receivableAmountStats.sum !== undefined) {
            this.receivableAmountStats =
                returnObject.stats.receivableAmountStats.sum;
          }
          if (returnObject.stats.transactionAmountStats.sum !== undefined) {
            this.transactionAmountStats =
                returnObject.stats.transactionAmountStats.sum;
          }
        } else {
          this.dataList = [];
          this.pagination.totalSize = 0;
          this.pagination.totalPages = 0;
          this.total = 0;
          this.discountAmountStats = 0;
          this.receivableAmountStats = 0;
          this.transactionAmountStats = 0;
        }
      }
    },
    //完结弹出窗
    complete(item) {
      this.completeDialogFormVisible = true;
      this.completeForm.parkingOrderIntoId = item;
    },
    //添加完结
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const parkingOrderIntoId = this.completeForm.parkingOrderIntoId;
          const endTime = this.completeForm.endTime;
          console.log(endTime);
          addParkingOrderExit(parkingOrderIntoId, endTime).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "完结成功",
                type: "success",
              });
              this.completeDialogFormVisible = false;
              this.doSearch();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置完结内容
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //修改车牌提交
    modifySubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const parkingOrderIntoId = this.modifyForm.parkingOrderIntoId;
          const color = this.modifyForm.color;
          const numPlate = this.modifyForm.numPlate;
          const reason = this.modifyForm.reason;

          updateVehicleNumPlate(
              parkingOrderIntoId,
              numPlate,
              color,
              reason
          ).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "修改车牌成功",
                type: "success",
              });
              this.modifyDialogFormVisible = false;
              this.doSearch();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭修改车牌
    closeModify() {
      this.modifyDialogFormVisible = false;
      this.modifyForm = {
        color: "",
        numPlate: "",
        reason: "",
      };
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    // },
    //修改车牌弹窗
    modifyLicensePlate(item) {
      this.modifyDialogFormVisible = true;
      this.modifyForm.parkingOrderIntoId = item;
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.query.timeValues.length > 0) {
        params["beginTime"] = this.query.timeValues[0];
        params["endTime"] = this.query.timeValues[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // const res = searchIncomeFlow(params);
      // var text = window.location.hostname;
      // console.log(text);
      // window.open("http://172.16.69.129:81" + "/ParkingManagermentController/getEsParkingOrderRecord?" + objectToQueryString(params));
      doExport(
          "/ParkingManagermentController/getEsParkingOrderRecord",
          params,
          "共享停车订单.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;

      // if (res && res.code === 30 && res.result) {
      //   loading.close();
      //   this.exportDialogFormVisible = false;
      // }
    },
    //获取当前时间
    getNowDate() {
      const beginToday = dateFormat("yyyy-mm-dd", new Date());
      const beginDate = beginToday + " 00:00:00";
      const endToday = dateFormat("yyyy-mm-dd", new Date());
      const endDate = endToday + " 23:59:59";
      this.query.timeValues = [beginDate, endDate];
    },
  },
  mounted() {
    // this.getNowDate();
  },
};
</script>

<style lang="less" scoped>
.photoShow {
  display: flex;
  flex-direction: row;

  .photo-width {
    /deep/ .el-carousel__container {
      width: 430px;
    }
  }

  .driveInPhoto {
    margin: 0 34px;
  }

  .driveOutPhoto {
    margin: 0 26px;
  }
}
</style>
